import VuexPersistence from 'vuex-persist';
import * as Cookies from 'js-cookie';

const isStrict = process.env.NODE_ENV !== 'production';

export const vuexLocalFlights = new VuexPersistence({
  strictMode: isStrict,
  storage: window.localStorage,
  key: 'vuex-flights',

  reducer: (state) => {
    const newState = {};

    const regexAllowedState = /\b(\w*flights)\b/gi;

    for (const item in state) {
      if (item.match(regexAllowedState)) {
        newState[item] = state[item];
      }
    }

    return newState;
  },
});

export const vuexCtxFlightCookies = new VuexPersistence({
  key: 'ctx_flight_id',
  reducer: (state) => ({
    flights: { selectedContextFlight: state.flights.selectedContextFlight },
  }),
  saveState: (key, state) => {
    Cookies.set(key, state.flights?.selectedContextFlight?.id, {
      expires: 365,
      secure: !isStrict,
    });
  },
});
