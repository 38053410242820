export const state = () => ({
  configs: {
    terms_and_conditions: null,
    help_and_feedback: null,
    privacy_policy: null,
    ready: false,
    restriction_title: null,
    restriction_text: null,
    pnrTitle: null,
    pnrNote: null,
    restrictionAcknowledged: null,
    seatHelpText: null,
  },
  embedded: false,
  isMobileWrapp: false,
  isShopV4: false,
  travelerId: null,
  displayTestData: false,
  showFlightPreorderAvailability: false,
  searchFlightByCodeShareNumber: false,
  shopOnlyMode: null,
  conciergeV3: null,
  conciergeShopPathV4: false,
  userLocation: {
    ip: '',
    city: 'New York',
    lat: 40.7128,
    lng: -74.006,
  },
  isOpenFlightSelector: false, // used for show/hide flight selector for default catalog features
});
