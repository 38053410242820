import VuexPersistence from 'vuex-persist';
import * as Cookies from 'js-cookie';
import * as flightsMutationTypes from './mutation-types';

const isStrict = process.env.NODE_ENV !== 'production';

export const vuexLocalFlights = new VuexPersistence({
  strictMode: isStrict,
  storage: window.localStorage,
  key: 'glx_flights',

  reducer: (state) => ({
    contextFlights: state.flights.contextFlights,
    selectedContextFlight: state.flights.selectedContextFlight,
  }),
  restoreState: (key, storage) => {
    const restoredState = JSON.parse(storage.getItem(key));
    const state = { flights: { ...restoredState } };
    return state;
  },
  filter: (mutation) => {
    if (!mutation.type.includes('flights')) return false;
    const allowedMutations = [
      flightsMutationTypes.FLIGHT_CONTEXT_CLEAR,
      flightsMutationTypes.FLIGHT_CONTEXT_APPEND_ALL,
      flightsMutationTypes.FLIGHT_CONTEXT_REMOVE,
      flightsMutationTypes.FLIGHT_SELECTED_CONTEXT_SET,
      flightsMutationTypes.FLIGHT_SELECTED_CONTEXT_CLEAR,
    ];
    const mutatuionType = mutation.type.split('/')[1];
    return allowedMutations.includes(mutatuionType);
  },
});

export const vuexCtxFlightCookies = new VuexPersistence({
  key: 'glx_flight',
  reducer: (state) => ({
    flights: { selectedContextFlight: state.flights.selectedContextFlight },
  }),
  saveState: (key, state) => {
    Cookies.set(
      key,
      { ...state.flights.selectedContextFlight },
      {
        expires: 365,
        secure: !isStrict,
      }
    );
  },
  restoreState: (key, storage) => {
    return { flights: { ...Cookies.getJSON(key) } };
  },
  filter: (mutation) => {
    if (!mutation.type.includes('flights')) return false;
    const allowedMutations = [
      flightsMutationTypes.FLIGHT_SELECTED_CONTEXT_SET,
      flightsMutationTypes.FLIGHT_SELECTED_CONTEXT_CLEAR,
    ];
    const mutatuionType = mutation.type.split('/')[1];
    return allowedMutations.includes(mutatuionType);
  },
});
