const prefixV3 = '/v3';

export const prefixPath = (path, prefixV = prefixV3) => {
  if (path.startsWith(prefixV)) {
    return path;
  } else if (path.startsWith(prefixV3) && prefixV !== prefixV3) {
    return path.replace(prefixV3, prefixV);
  }

  if (path.startsWith('/')) {
    return prefixV + path;
  } else {
    return `${prefixV}/${path}`;
  }
};

export const isNuxtPath = (path, prefixV = prefixV3) => {
  return path.toLowerCase().startsWith(prefixV);
};

export const PathPrefixPlugin = {
  async install(Vue) {
    Vue.prototype.$pathPrefix = prefixPath;
    Vue.prototype.$isNuxtPath = isNuxtPath;
  },
};
