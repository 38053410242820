import Cookies from 'js-cookie';

export const state = () => {
  const cabinClassCookie = Cookies.get('glxCabinClass');
  const selectedCabinClass =
    cabinClassCookie?.length > 0 ? JSON.parse(cabinClassCookie) : null;

  return {
    conciergeModuleContent: {
      error: null,
      isLoading: true,
      value: null,
    },
    conciergeStages: {
      error: null,
      isLoading: false,
      stages: null,
    },
    selectedStage: {
      id: null,
      stage: 0,
      subtitle: '',
      title: '',
    },
    cabinClass: {
      selectedCabinClass: selectedCabinClass,
      allowCabinClasstooltip: selectedCabinClass ? false : true,
    },
  };
};
