import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { mapboxService } from '@/core/services/MapboxService';
import axios from 'axios';
import Vue from 'vue';
import { RepoFactory } from '@/core/repositories';
import { SIDECAR_API_PATH } from '../../sidecar-env';

export const actions = {
  async loadConfigs({ state, commit, dispatch }, { vm }) {
    let endpoint = 'configs-v2';
    try {
      const { data } = await axios.get(`${SIDECAR_API_PATH}/${endpoint}`);
      commit('configsLoaded', { configs: data });
      dispatch('initConfigs', { vm });
    } catch (error) {
      let endpoint = 'configs';
      const { data } = await axios.get(`${SIDECAR_API_PATH}/${endpoint}`);
      commit('configsLoaded', { configs: data });
      dispatch('initConfigs', { vm });
      return;
    }
  },

  initConfigs({ state, commit, dispatch }, { vm }) {
    const {
      stripe_key,
      sentry_dsn,
      featureFlags,
      mapbox_token,
      launchdarkly_client_id,
      pusher_api_key,
      env,
    } = state.configs;
    dispatch('initStripe', { stripe_key, vm });
    dispatch('initSentry', { sentry_dsn });
    dispatch('initLaunchDarkly', { launchdarkly_client_id, env, vm });
    dispatch('initMapbox', { mapbox_token });

    if (featureFlags) {
      dispatch(
        'featureFlags/updateAllFeatures',
        { features: featureFlags },
        { root: true }
      );
    }

    commit('clearConfigKeys', [
      'sentry_dsn',
      'featureFlags',
      'mapbox_token',
      'launchdarkly_client_id',
    ]);
  },

  initFlightRestriction({ state }) {
    const { flight_prefix_restriction } = state.configs;
    if (flight_prefix_restriction && flight_prefix_restriction.pattern) {
      const flightRepo = RepoFactory.get('flight');
      flightRepo.setFlightRestriction(flight_prefix_restriction);
    }
  },

  initSentry(_, { sentry_dsn }) {
    if (!sentry_dsn || process.env.NODE_ENV == null) return;
    Sentry.init({
      Vue,
      release: process.env.RELEASE,
      environment: process.env.NODE_ENV,
      dsn: sentry_dsn,
      logErrors: process.env.NODE_ENV !== 'production',
      tracingOptions: {
        trackComponents: true,
      },
      integrations: [new Integrations.BrowserTracing()],
      tracesSampler: (samplingContext) => {
        const locationOrigin = samplingContext.location.origin;
        if (
          process.env.NODE_ENV !== 'production' ||
          locationOrigin.includes('.dev') ||
          locationOrigin.includes('.rc') ||
          locationOrigin.includes('.sbox')
        )
          return 0;
        else return 0.01;
      },
    });
  },

  initStripe(_, { stripe_key, vm }) {
    if (!stripe_key) return;
    vm.$stripe.init(stripe_key);
  },

  initMapbox(_, { mapbox_token }) {
    if (!mapbox_token) return;
    mapboxService.init(mapbox_token);
  },

  initLaunchDarkly({ state }, { launchdarkly_client_id, env, vm }) {
    if (!launchdarkly_client_id) return;
    const user = {
      key: state.configs['app-id'],
    };
    vm.$ldclient.initialize(launchdarkly_client_id, user);
  },

  initPusher({ state }, { pusher_api_key, vm }) {
    if (!pusher_api_key) return;
    vm.$pusher.initialize(pusher_api_key);
  },

  setConfigs({ commit }, configs) {
    commit('configsLoaded', { configs });
  },

  setConciergeShopPathV4({ commit }, value) {
    commit('setConciergeShopPathV4', value);
  },

  enableEmbedded({ commit }) {
    commit('setEmbedded', true);
  },

  isMobileWrapp({ commit }, val) {
    commit('setMobileWrapp', val);
  },

  setTravelerId({ commit }, id) {
    commit('traverlerIdLoaded', id);
  },

  disableEmbedded({ commit }) {
    commit('setEmbedded', false);
  },

  onDisplayTestDataValueChange({ commit }, value) {
    commit('setDisplayTestDataValue', value);
  },

  onShowFlightPreorderAvailability({ commit }, value) {
    commit('setShowFlightPreorderAvailability', value);
  },

  onSearchFlightByCodeShareNumber({ commit }, value) {
    commit('setSearchFlightByCodeShareNumber', value);
  },

  onShopOnlyMode({ commit }, value) {
    commit('setShopOnlyMode', value);
  },

  checkConciergeV3({ commit }, value) {
    commit('setConciergeV3', value);
  },

  isShopV4({ commit }, value) {
    commit('setShopV4', value);
  },

  async getUserIp({ commit }) {
    try {
      const ip = await axios.get('https://ipv4.icanhazip.com');
      commit('setUserLocation', { ip: ip.data });
    } catch (error) {
      return;
    }
  },

  async getUserCoords({ state, commit }) {
    const userIp = state.userLocation.ip;
    try {
      const coords = await axios.get(
        `https://api.ipstack.com/${userIp}?access_key=9867f709e99eb225e09f74f281f73f4c`
      );
      if (coords.data.latitude && coords.data.longitude) {
        commit('setUserLocation', {
          lat: coords.data.latitude,
          lng: coords.data.longitude,
        });
      }
    } catch (error) {
      return;
    }
  },

  async getUserCity({ state, commit }) {
    // it seems that http://api.ipstack.com/ doesn't detect user city accurate enough,
    // for this purpose we use mapboxService
    const userLocation = state.userLocation;
    try {
      const places = await mapboxService.reverseGeocode({
        lat: userLocation.lat,
        lng: userLocation.lng,
      });
      const userCity = places.features.filter((item) =>
        item.id.includes('place')
      )[0];
      if (userCity) {
        commit('setUserLocation', {
          city: userCity.place_name,
        });
      }
    } catch (error) {
      return;
    }
  },

  async getUserLocation({ state, dispatch }) {
    await dispatch('getUserIp');
    await dispatch('getUserCoords');
    await dispatch('getUserCity');
  },

  setFlightSelectorStatus({ commit }, value) {
    commit('setFlightSelectorStatus', value);
  },
};
