import Vue from 'vue';
import Vuex from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';
import { state } from './state';
import { getters } from './getters';
import ProductStoreModule from '../modules/product/store/index';
import AdvicaStoreModule from '../modules/product/advica/store/index';
import FeatureFlagsModule from './modules/featureFlags/';
import HomePageStoreModule from '../modules/home/store/index';
import FlightsStoreModule from '../modules/flights/store/index';
import OrdersStoreModule from '../modules/orders/store/index';
import PaymentStoreModule from '../modules/payment/store/index';
import TransportationModule from './modules/transportation/index';
import DiscoveryModule from '../modules/discovery/store/discovery/index';
import WebItemsModule from '../modules/web-items/store/index';
import DiningModule from './modules/dining/index';
import ConciergeModule from '../modules/concierge/store/index';
import InformationModule from '../modules/information/store/index';
import ShopCartModule from '@/store/modules/shopCart';
import {
  vuexLocalFlights,
  vuexCtxFlightCookies,
  vuexLocalFlightsLegacy,
  vuexCtxFlightCookiesLegacy,
} from './plugins';

Vue.use(Vuex);

const isStrict = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  namespaced: true,
  strict: isStrict,
  state: state,
  mutations: {
    ...mutations,
    RESTORE_MUTATION: isStrict ? vuexLocalFlights.RESTORE_MUTATION : undefined,
  },
  actions,
  getters,
  modules: {
    product: ProductStoreModule,
    advica: AdvicaStoreModule,
    featureFlags: FeatureFlagsModule,
    home: HomePageStoreModule,
    payment: PaymentStoreModule,
    orders: OrdersStoreModule,
    flights: FlightsStoreModule,
    transportation: TransportationModule,
    discovery: DiscoveryModule,
    webItems: WebItemsModule,
    dining: DiningModule,
    concierge: ConciergeModule,
    information: InformationModule,
    shopCart: ShopCartModule,
  },
  plugins: [
    vuexCtxFlightCookiesLegacy.plugin,
    vuexLocalFlightsLegacy.plugin,
    vuexCtxFlightCookies.plugin,
    vuexLocalFlights.plugin,
  ],
});
