export const getters = {
  hasConciergeModuleContent: (state) =>
    !!state.conciergeModuleContent &&
    !state.conciergeModuleContent.isLoading &&
    !!state.conciergeModuleContent.value,

  hasConciergeModuleContentError: (state) =>
    !!state.conciergeModuleContent &&
    Boolean(state.conciergeModuleContent.error),

  hasConciergeStagesError: (state) =>
    !!state.conciergeStages && Boolean(state.conciergeStages.error),

  hasConciergeCategories: (state) => {
    !!state.conciergeModuleContent &&
      !state.conciergeModuleContent.isLoading &&
      !!state.conciergeModuleContent.value &&
      !!state.conciergeModuleContent.value.modules;
    !!state.conciergeModuleContent.value.modules.length;
  },
  selectedCabinClass: (state) => state.cabinClass.selectedCabinClass,
  allowCabinClasstooltip: (state) => state.cabinClass.allowCabinClasstooltip,
};
