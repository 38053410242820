export const CONCIERGE_MODULE_CONTENT_LOADING =
  'CONCIERGE_MODULE_ITEMS_LOADING';
export const CONCIERGE_MODULE_CONTENT_LOADED = 'CONCIERGE_MODULE_ITEMS_LOADED';
export const CONCIERGE_MODULE_CONTENT_ERROR = 'CONCIERGE_MODULE_ITEMS_ERROR';
export const CONCIERGE_STAGES_LOADING = 'CONCIERGE_STAGES__LOADING';
export const CONCIERGE_STAGES_LOADED = 'CONCIERGE_STAGES__LOADED';
export const CONCIERGE_STAGES_ERROR = 'CONCIERGE_STAGES__ERROR';
export const UPDATE_STAGE = 'UPDATE_STAGE';
export const UPDATE_CABIN_CLASS = 'UPDATE_CABIN_CLASS';
export const UPDATE_CABIN_CLASS_TOOLTIP = 'UPDATE_CABIN_CLASS_TOOLTIP';
