import * as mutationTypes from './mutation-types';

export const mutations = {
  [mutationTypes.UPDATE_CABIN_CLASS](state, cabinClass) {
    state.cabinClass.selectedCabinClass = { ...cabinClass };
  },
  [mutationTypes.CONCIERGE_MODULE_CONTENT_LOADED](state, value) {
    state.conciergeModuleContent = {
      ...state.conciergeModuleContent,
      value,
    };
  },
  [mutationTypes.CONCIERGE_MODULE_CONTENT_LOADING](state, payload = true) {
    state.conciergeModuleContent = {
      ...state.conciergeModuleContent,
      isLoading: payload,
    };
  },
  [mutationTypes.UPDATE_CABIN_CLASS_TOOLTIP](state, payload) {
    state.cabinClass.allowCabinClasstooltip = payload;
  },
  [mutationTypes.CONCIERGE_MODULE_CONTENT_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.conciergeModuleContent = {
      ...state.conciergeModuleContent,
      value: null,
      error: error,
    };
  },
  [mutationTypes.CONCIERGE_STAGES_LOADED](state, { stages } = { stages: [] }) {
    state.conciergeStages = {
      ...state.conciergeStages,
      stages: [...stages],
    };
  },
  [mutationTypes.CONCIERGE_STAGES_LOADING](state, payload = true) {
    state.conciergeStages = {
      ...state.conciergeStages,
      isLoading: payload,
    };
  },
  [mutationTypes.CONCIERGE_STAGES_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.conciergeStages = {
      ...state.conciergeStages,
      stages: null,
      error: error,
    };
  },
  [mutationTypes.UPDATE_STAGE](state, stage) {
    state.selectedStage = {
      ...state.selectedStage,
      ...stage,
    };
  },
};
